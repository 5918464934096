@import url(react-image-lightbox/style.css);
.container {
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (max-width: 576px) {
    .container {
      width: 95%;
      padding-right: 16px;
      padding-left: 16px; } }
  @media screen and (min-width: 576px) {
    .container {
      width: calc(94%);
      max-width: 1380px;
      padding-right: 24px;
      padding-left: 24px; } }

.externalWebsiteLink {
  text-decoration: none;
  color: #bb86fc;
  font-style: italic; }
  .externalWebsiteLink:visited {
    color: #CF6679; }
  .externalWebsiteLink:hover {
    text-decoration: underline; }
  .externalWebsiteLink:active {
    color: red; }
  .externalWebsiteLink:focus {
    outline: 2px dashed gray; }

:root {
  position: absolute;
  left: 0;
  right: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 1.2;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  --jaffa-font-color: rgb(28, 28, 40);
  --jaffa-font-color-secondary: #555770;
  --jaffa-background-color: #f4f4f5;
  --jaffa-background-color-2: #eeeeea; }
  @media screen and (max-width: 768px) {
    :root {
      font-size: 16px; } }
  :root h1 {
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 34px;
    font-weight: 700; }
    @media screen and (max-width: 768px) {
      :root h1 {
        font-size: 24px;
        font-weight: 500; } }
  :root p {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400; }
    @media screen and (max-width: 768px) {
      :root p {
        font-size: 16px; } }

/* 2 */
[data-theme="dark"] {
  --jaffa-font-color: #eee;
  --jaffa-font-color-secondary: #C7C9D9;
  --jaffa-background-color: #1c1c28;
  --jaffa-background-color-2: #28293D; }

#navbar {
  position: sticky;
  top: 0;
  height: 64px;
  background-color: var(--jaffa-background-color);
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  z-index: 10;
  backdrop-filter: blur(25px); }
  #navbar.add-shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    background-color: rgba(var(--jaffa-background-color), 0.1); }

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center; }

.navbar-item {
  margin: 8px; }

.home-link {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  -webkit-transition: none;
  transition: none; }
  .home-link span {
    display: flex;
    gap: 4px;
    font-size: 24px;
    font-family: "Copperplate", serif;
    font-weight: 600;
    margin-top: 6px;
    background: linear-gradient(147.14deg, #6600CC 6.95%, #FF3B3B 93.05%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: -1px -1px 0 rgba(102, 0, 204, 0.8); }
    @media screen and (max-width: 888px) {
      .home-link span {
        font-size: 24px; }
        .home-link span div:nth-of-type(1) {
          display: none; } }
  .home-link img {
    max-height: 32px; }
    .home-link img:hover {
      -webkit-animation: spin 2s ease-in-out;
      -moz-animation: spin 2s ease-in-out;
      animation: spin 2s ease-in-out; }

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.navbar-link {
  color: var(--jaffa-font-color);
  /* unvisited link */
  text-decoration-line: none;
  /* visited link */
  /* mouse over link */
  /* selected link */
  display: flex;
  align-items: center;
  transition: background 0.5s ease-in-out; }
  .navbar-link:link {
    color: var(--jaffa-font-color); }
  .navbar-link:visited {
    color: var(--jaffa-font-color); }
  .navbar-link:hover {
    text-shadow: 0px 0px 1px var(--jaffa-font-color);
    border-radius: 24px; }
  .navbar-link:active {
    color: var(--jaffa-font-color); }

.navbar-link {
  justify-content: center;
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  backdrop-filter: blur(25px); }

.navbar-logo {
  width: 30px;
  height: 30px; }

#navbar-toggle {
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  right: 20px; }

.icon-bar {
  display: block;
  width: 25px;
  height: 4px;
  margin: 2px;
  background: linear-gradient(147.14deg, #FF8800 6.95%, #E63535 93.05%);
  transition: all 0.5s; }

#navbar-toggle:is(:focus, :hover) .icon-bar {
  background-color: #FF8800; }

#navbar-toggle[aria-expanded="true"] .icon-bar:is(:first-child, :last-child) {
  position: absolute;
  margin: 0;
  width: 30px; }

#navbar-toggle[aria-expanded="true"] .icon-bar:first-child {
  transform: rotate(45deg); }

#navbar-toggle[aria-expanded="true"] .icon-bar:nth-child(2) {
  opacity: 0; }

#navbar-toggle[aria-expanded="true"] .icon-bar:last-child {
  transform: rotate(-45deg); }

#navbar-menu {
  position: absolute;
  top: 55px;
  opacity: 0;
  visibility: hidden;
  left: 0;
  right: 0; }

#navbar-toggle[aria-expanded="true"] + #navbar-menu {
  opacity: 1;
  visibility: visible; }

.navbar-links {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  margin: 0.8rem;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  transition: all 0.5s; }
  @media screen and (max-width: 700px) {
    .navbar-links {
      background-color: var(--jaffa-background-color);
      opacity: 0.9; } }

#navbar-toggle[aria-expanded="true"] + #navbar-menu .navbar-links {
  padding: 1em;
  backdrop-filter: blur(5px); }

@media screen and (min-width: 700px) {
  #navbar-toggle,
  #navbar-toggle[aria-expanded="true"] {
    display: none; }
  #navbar-menu,
  #navbar-toggle[aria-expanded="true"] #navbar-menu {
    visibility: visible;
    opacity: 1;
    position: static;
    display: block;
    height: 100%; }
  .navbar-links,
  #navbar-toggle[aria-expanded="true"] #navbar-menu .navbar-links {
    margin: 0;
    padding: 0;
    box-shadow: none;
    flex-direction: row;
    width: 100%;
    height: 100%; } }

.container {
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (max-width: 576px) {
    .container {
      width: 95%;
      padding-right: 16px;
      padding-left: 16px; } }
  @media screen and (min-width: 576px) {
    .container {
      width: calc(94%);
      max-width: 1380px;
      padding-right: 24px;
      padding-left: 24px; } }

.externalWebsiteLink {
  text-decoration: none;
  color: #bb86fc;
  font-style: italic; }
  .externalWebsiteLink:visited {
    color: #CF6679; }
  .externalWebsiteLink:hover {
    text-decoration: underline; }
  .externalWebsiteLink:active {
    color: red; }
  .externalWebsiteLink:focus {
    outline: 2px dashed gray; }

:root {
  position: absolute;
  left: 0;
  right: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 1.2;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  --jaffa-font-color: rgb(28, 28, 40);
  --jaffa-font-color-secondary: #555770;
  --jaffa-background-color: #f4f4f5;
  --jaffa-background-color-2: #eeeeea; }
  @media screen and (max-width: 768px) {
    :root {
      font-size: 16px; } }
  :root h1 {
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 34px;
    font-weight: 700; }
    @media screen and (max-width: 768px) {
      :root h1 {
        font-size: 24px;
        font-weight: 500; } }
  :root p {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400; }
    @media screen and (max-width: 768px) {
      :root p {
        font-size: 16px; } }

/* 2 */
[data-theme="dark"] {
  --jaffa-font-color: #eee;
  --jaffa-font-color-secondary: #C7C9D9;
  --jaffa-background-color: #1c1c28;
  --jaffa-background-color-2: #28293D; }

.buttonJ {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  font-size: 18px;
  font-weight: 600;
  width: fit-content;
  line-height: 1.6;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  text-shadow: 0px 0px 1px var(--jaffa-font-color);
  color: #fafafc;
  background: linear-gradient(147.14deg, #3E7BFA 6.95%, #6600CC 93.05%);
  padding: 8px 16px;
  cursor: pointer; }
  .buttonJ__primary {
    background: linear-gradient(147.14deg, #E63535 6.95%, #FF8800 93.05%); }
  .buttonJ__secondary {
    background: linear-gradient(147.14deg, #3E7BFA 6.95%, #6600CC 93.05%); }
  .buttonJ__tertiary {
    background: linear-gradient(147.14deg, #FF3B3B 6.95%, #6600CC 93.05%); }
  .buttonJ__twitterGradient {
    background: linear-gradient(147.14deg, #73DFE7 6.95%, #0063F7 93.05%); }
  .buttonJ__rounded {
    border-radius: 24px; }
  .buttonJ__contentFit {
    width: fit-content; }
  .buttonJ__transparent {
    background: transparent; }
  .buttonJ__small {
    font-size: 14px; }
  .buttonJ__bounce:hover {
    text-transform: none;
    transform: translateY(-2px); }
    .buttonJ__bounce:hover:hover, .buttonJ__bounce:hover:focus {
      box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19); }
  .buttonJ__children {
    display: flex;
    justify-content: center;
    align-items: center; }
  .buttonJ:hover {
    color: white; }

.container {
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (max-width: 576px) {
    .container {
      width: 95%;
      padding-right: 16px;
      padding-left: 16px; } }
  @media screen and (min-width: 576px) {
    .container {
      width: calc(94%);
      max-width: 1380px;
      padding-right: 24px;
      padding-left: 24px; } }

.externalWebsiteLink {
  text-decoration: none;
  color: #bb86fc;
  font-style: italic; }
  .externalWebsiteLink:visited {
    color: #CF6679; }
  .externalWebsiteLink:hover {
    text-decoration: underline; }
  .externalWebsiteLink:active {
    color: red; }
  .externalWebsiteLink:focus {
    outline: 2px dashed gray; }

:root {
  position: absolute;
  left: 0;
  right: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 1.2;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  --jaffa-font-color: rgb(28, 28, 40);
  --jaffa-font-color-secondary: #555770;
  --jaffa-background-color: #f4f4f5;
  --jaffa-background-color-2: #eeeeea; }
  @media screen and (max-width: 768px) {
    :root {
      font-size: 16px; } }
  :root h1 {
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 34px;
    font-weight: 700; }
    @media screen and (max-width: 768px) {
      :root h1 {
        font-size: 24px;
        font-weight: 500; } }
  :root p {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400; }
    @media screen and (max-width: 768px) {
      :root p {
        font-size: 16px; } }

/* 2 */
[data-theme="dark"] {
  --jaffa-font-color: #eee;
  --jaffa-font-color-secondary: #C7C9D9;
  --jaffa-background-color: #1c1c28;
  --jaffa-background-color-2: #28293D; }

.postCard {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #8b8db0;
  padding: 8px;
  border-radius: 8px; }
  .postCard__content {
    display: flex;
    text-wrap: normal; }
  .postCard__category {
    color: #eee;
    text-shadow: 1px 1px 10px #28293d;
    width: max-content;
    padding: 1px 10px;
    border-radius: 8px;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: -20px; }
    .postCard__category__watchlist {
      background: linear-gradient(147.14deg, #FF3B3B 6.95%, #6600CC 93.05%); }
    .postCard__category__buy-alert {
      background: linear-gradient(147.14deg, #73DFE7 6.95%, #0063F7 93.05%); }
    .postCard__category__market-update {
      background: linear-gradient(147.14deg, #FF8800 6.95%, #E63535 93.05%); }
    .postCard__category__general {
      background: linear-gradient(147.14deg, #3E7BFA 6.95%, #6600CC 93.05%); }
  .postCard__postedTime {
    margin-top: 20px;
    display: flex;
    font-size: 11px;
    font-weight: 400;
    color: var(--jaffa-font-color-secondary);
    justify-content: end;
    gap: 20px; }
    @media screen and (max-width: 768px) {
      .postCard__postedTime {
        margin-top: 10px;
        font-size: 9px;
        gap: 10px; } }
  .postCard__images {
    display: flex;
    gap: 10px;
    column-count: 3;
    column-width: 33%;
    margin-top: 20px; }
    @media screen and (max-width: 900px) {
      .postCard__images {
        column-count: 2; } }
    .postCard__images__image {
      cursor: pointer;
      transition: all 350ms ease;
      margin-bottom: 12px; }
      .postCard__images__image:hover {
        filter: opacity(0.8); }
  .postCard__twitterShare {
    margin-left: auto;
    margin-top: 5px;
    gap: 10px;
    padding: 4px 8px; }
    .postCard__twitterShare__icon {
      padding-right: 5px; }

.container {
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (max-width: 576px) {
    .container {
      width: 95%;
      padding-right: 16px;
      padding-left: 16px; } }
  @media screen and (min-width: 576px) {
    .container {
      width: calc(94%);
      max-width: 1380px;
      padding-right: 24px;
      padding-left: 24px; } }

.externalWebsiteLink {
  text-decoration: none;
  color: #bb86fc;
  font-style: italic; }
  .externalWebsiteLink:visited {
    color: #CF6679; }
  .externalWebsiteLink:hover {
    text-decoration: underline; }
  .externalWebsiteLink:active {
    color: red; }
  .externalWebsiteLink:focus {
    outline: 2px dashed gray; }

:root {
  position: absolute;
  left: 0;
  right: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 1.2;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  --jaffa-font-color: rgb(28, 28, 40);
  --jaffa-font-color-secondary: #555770;
  --jaffa-background-color: #f4f4f5;
  --jaffa-background-color-2: #eeeeea; }
  @media screen and (max-width: 768px) {
    :root {
      font-size: 16px; } }
  :root h1 {
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 34px;
    font-weight: 700; }
    @media screen and (max-width: 768px) {
      :root h1 {
        font-size: 24px;
        font-weight: 500; } }
  :root p {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400; }
    @media screen and (max-width: 768px) {
      :root p {
        font-size: 16px; } }

/* 2 */
[data-theme="dark"] {
  --jaffa-font-color: #eee;
  --jaffa-font-color-secondary: #C7C9D9;
  --jaffa-background-color: #1c1c28;
  --jaffa-background-color-2: #28293D; }

.RichEditor-root {
  border: 1px solid #999; }
  .RichEditor-root__readOnly {
    border: none; }

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px; }

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px; }

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  /*margin-top: 10px;*/ }
  .RichEditor-editor__readOnly {
    border-top: none; }
    .RichEditor-editor__readOnly .public-DraftEditor-content {
      min-height: fit-content;
      padding: 0px 0px 0px 15px;
      margin-top: -5px; }

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none; }

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px; }

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px; }

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none; }

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block; }

.RichEditor-activeButton {
  color: #5890ff; }

.authForm {
  min-width: 50%; }
  .authForm .input-group-text {
    background: white;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px; }
    .authForm .input-group-text__eye {
      border-radius: 0px 4px 4px 0px; }
  .authForm i {
    background: transparent; }

.container {
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (max-width: 576px) {
    .container {
      width: 95%;
      padding-right: 16px;
      padding-left: 16px; } }
  @media screen and (min-width: 576px) {
    .container {
      width: calc(94%);
      max-width: 1380px;
      padding-right: 24px;
      padding-left: 24px; } }

.externalWebsiteLink {
  text-decoration: none;
  color: #bb86fc;
  font-style: italic; }
  .externalWebsiteLink:visited {
    color: #CF6679; }
  .externalWebsiteLink:hover {
    text-decoration: underline; }
  .externalWebsiteLink:active {
    color: red; }
  .externalWebsiteLink:focus {
    outline: 2px dashed gray; }

:root {
  position: absolute;
  left: 0;
  right: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 1.2;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  --jaffa-font-color: rgb(28, 28, 40);
  --jaffa-font-color-secondary: #555770;
  --jaffa-background-color: #f4f4f5;
  --jaffa-background-color-2: #eeeeea; }
  @media screen and (max-width: 768px) {
    :root {
      font-size: 16px; } }
  :root h1 {
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 34px;
    font-weight: 700; }
    @media screen and (max-width: 768px) {
      :root h1 {
        font-size: 24px;
        font-weight: 500; } }
  :root p {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400; }
    @media screen and (max-width: 768px) {
      :root p {
        font-size: 16px; } }

/* 2 */
[data-theme="dark"] {
  --jaffa-font-color: #eee;
  --jaffa-font-color-secondary: #C7C9D9;
  --jaffa-background-color: #1c1c28;
  --jaffa-background-color-2: #28293D; }

.circle-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  position: relative;
  border-radius: 50%; }

.app-logo {
  position: absolute;
  z-index: -1; }

#play-icon {
  overflow: hidden; }

#tri-first {
  /*   display: none; */
  position: absolute;
  left: -20px; }

#tri-second {
  fill: #6ed69a; }

#tri-third {
  margin-left: -3px; }

#bulb-svg {
  transform: rotate(30deg);
  height: 60px;
  width: 60px; }

#bulb {
  filter: drop-shadow(0px 10px 6px rgba(255, 204, 0, 0.4));
  border-radius: 2px; }

.spinning-hexagon {
  height: auto;
  width: auto; }

.animated-svg {
  height: 60px;
  width: 60px; }

#lines {
  stroke: rgba(255, 255, 255, 0.8); }

.animated-svg #symbol {
  transform-origin: center center;
  animation-name: spinFull;
  animation-duration: 6s;
  animation-iteration-count: infinite; }

.animated-svg #colors {
  transform-origin: center center;
  animation-name: spinPiece;
  animation-duration: 6s;
  animation-iteration-count: infinite; }

.animated-svg #lines {
  transform-origin: center center;
  animation-name: spinBackHalf;
  animation-duration: 6s;
  animation-iteration-count: infinite; }

#box-connectors {
  stroke: #777777; }

@keyframes spinFull {
  0%, 25% {
    transform: rotate(0deg); }
  25%, 50% {
    transform: rotate(360deg); }
  50%, 75% {
    transform: rotate(360deg); }
  75%, 100% {
    transform: rotate(-360deg); } }

@keyframes spinPiece {
  0%, 25% {
    transform: rotate(0deg); }
  25%, 50% {
    transform: rotate(60deg); }
  50%, 75% {
    transform: rotate(60deg); }
  75%, 100% {
    transform: rotate(0deg); } }

@keyframes spinHalf {
  0%, 25% {
    transform: rotate(0deg) scale(1); }
  25%, 50% {
    transform: rotate(180deg) scale(1); }
  50%, 75% {
    transform: rotate(180deg) scale(1); }
  75%, 100% {
    transform: rotate(0deg) scale(1); } }

@keyframes spinBackHalf {
  0%, 25% {
    transform: rotate(0deg); }
  25%, 50% {
    transform: rotate(-180deg); }
  50%, 75% {
    transform: rotate(-180deg); }
  75%, 100% {
    transform: rotate(0deg); } }

@keyframes spinBackFull {
  0%, 25% {
    transform: rotate(0deg); }
  25%, 50% {
    transform: rotate(-360deg); }
  50%, 75% {
    transform: rotate(-360deg); }
  75%, 100% {
    transform: rotate(0deg); } }

.home {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  padding-top: 10px;
  width: 100%;
  align-items: center; }
  @media screen and (max-width: 788px) {
    .home {
      margin-top: 92px; } }
  .home__topSection {
    display: flex;
    padding: 60px 0px 0px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: Inter, sans-serif; }
    @media screen and (max-width: 900px) {
      .home__topSection {
        padding: 30px 0px; } }
    .home__topSection span {
      background: linear-gradient(147.14deg, #6600CC 6.95%, #FF3B3B 93.05%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
    .home__topSection__title {
      font-size: 64px;
      font-weight: 900; }
      @media screen and (max-width: 900px) {
        .home__topSection__title {
          font-size: 48px; } }
      @media screen and (max-width: 670px) {
        .home__topSection__title {
          font-size: 40px; } }
    .home__topSection__subtitle {
      padding: 20px 10px;
      font-size: 18px; }
  .home__hero {
    display: grid;
    grid-template-columns: 3fr 5fr;
    gap: 20px;
    justify-content: space-between;
    padding: 30px 5px; }
    .home__hero__animation {
      max-height: 500px;
      max-width: 500px;
      min-height: 150px;
      min-width: 150px;
      aspect-ratio: 1/1;
      align-self: center;
      filter: grayscale(0.3); }
      @media screen and (max-width: 300px) {
        .home__hero__animation {
          min-height: 100px;
          min-width: 100px; } }
    .home__hero__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center; }
  .home__improveSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 5px 50px 5px;
    background-color: var(--jaffa-background-color-2); }
    .home__improveSection div {
      font-size: 48px;
      font-weight: 700; }
      @media screen and (max-width: 768px) {
        .home__improveSection div {
          font-size: 36px; } }
    .home__improveSection__title {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: 500; }
  .home__tradingSteps {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 48px;
    justify-content: space-evenly;
    padding-bottom: 50px; }
    .home__tradingSteps__hero {
      width: 100%;
      text-align: center;
      padding: 10px 0px; }
    .home__tradingSteps__step {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      height: 150px;
      width: 150px;
      cursor: pointer;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: inset -0.5px -0.5px 0px 0px white;
      border: 1px solid rgba(255, 255, 255, 0.5);
      transition: all 0.5s;
      font-size: 15px;
      color: white; }
      @media screen and (max-width: 1080px) {
        .home__tradingSteps__step {
          height: 120px;
          width: 120px; }
          .home__tradingSteps__step h6 {
            font-size: 13px; } }
      .home__tradingSteps__step:hover {
        transform: scale(1.05); }
    .home__tradingSteps__selection {
      background-color: #8f2f2f; }
    .home__tradingSteps__timing {
      background-color: #2b868c; }
    .home__tradingSteps__positionManagement {
      background-color: #34378a; }
    .home__tradingSteps__managing {
      background-color: #882d8c; }
    .home__tradingSteps__selling {
      background-color: #785807; }
    .home__tradingSteps__markethealth {
      background-color: #5a1d98; }
    .home__tradingSteps__mindset {
      background-color: #004D4D; }
  .home__footer {
    margin-top: 30px;
    background-color: var(--jaffa-background-color-2);
    width: 100%;
    padding: 50px 0px; }
    .home__footer__title {
      gap: 4px; }
      .home__footer__title__logo {
        display: flex;
        align-items: flex-end;
        color: #773dbc;
        font-family: "Copperplate", serif;
        font-size: 24px;
        font-weight: 600; }
      .home__footer__title__purpose {
        padding: 5px 0px;
        max-width: 500px; }
      .home__footer__title__social {
        padding: 5px 0px;
        display: flex;
        gap: 24px; }
        .home__footer__title__social__twitter a {
          color: #1da1f2; }
          .home__footer__title__social__twitter a i {
            font-size: 28px; }
        .home__footer__title__social__linkedin a {
          color: #0077b5; }
          .home__footer__title__social__linkedin a i {
            font-size: 28px; }
        .home__footer__title__social__instagram a {
          color: #fb3958; }
          .home__footer__title__social__instagram a i {
            font-size: 28px; }
        .home__footer__title__social__youtube a {
          color: #ff0000; }
          .home__footer__title__social__youtube a i {
            font-size: 28px; }
        .home__footer__title__social__github a {
          color: #FF8800; }
          .home__footer__title__social__github a i {
            font-size: 28px; }
    .home__footer__resources {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      grid-gap: 20px;
      margin-top: 20px;
      padding-top: 20px;
      padding-bottom: 50px; }
      @media screen and (max-width: 360px) {
        .home__footer__resources {
          grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)); } }
      .home__footer__resources__section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        min-width: 150px;
        max-width: 200px; }
        .home__footer__resources__section__title {
          font-weight: 300; }
        .home__footer__resources__section__link {
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 5px; }
          .home__footer__resources__section__link__twitter a {
            color: var(--jaffa-font-color); }
            .home__footer__resources__section__link__twitter a:hover {
              color: #1da1f2; }
          .home__footer__resources__section__link__linkedin a {
            color: var(--jaffa-font-color); }
            .home__footer__resources__section__link__linkedin a:hover {
              color: #0077b5; }
          .home__footer__resources__section__link__instagram a {
            color: var(--jaffa-font-color); }
            .home__footer__resources__section__link__instagram a:hover {
              color: #fb3958; }
          .home__footer__resources__section__link__youtube a {
            color: var(--jaffa-font-color); }
            .home__footer__resources__section__link__youtube a:hover {
              color: #ff0000; }
          .home__footer__resources__section__link__github a {
            color: var(--jaffa-font-color); }
            .home__footer__resources__section__link__github a:hover {
              color: #FF8800; }

.container {
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (max-width: 576px) {
    .container {
      width: 95%;
      padding-right: 16px;
      padding-left: 16px; } }
  @media screen and (min-width: 576px) {
    .container {
      width: calc(94%);
      max-width: 1380px;
      padding-right: 24px;
      padding-left: 24px; } }

.externalWebsiteLink {
  text-decoration: none;
  color: #bb86fc;
  font-style: italic; }
  .externalWebsiteLink:visited {
    color: #CF6679; }
  .externalWebsiteLink:hover {
    text-decoration: underline; }
  .externalWebsiteLink:active {
    color: red; }
  .externalWebsiteLink:focus {
    outline: 2px dashed gray; }

:root {
  position: absolute;
  left: 0;
  right: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 1.2;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  --jaffa-font-color: rgb(28, 28, 40);
  --jaffa-font-color-secondary: #555770;
  --jaffa-background-color: #f4f4f5;
  --jaffa-background-color-2: #eeeeea; }
  @media screen and (max-width: 768px) {
    :root {
      font-size: 16px; } }
  :root h1 {
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 34px;
    font-weight: 700; }
    @media screen and (max-width: 768px) {
      :root h1 {
        font-size: 24px;
        font-weight: 500; } }
  :root p {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400; }
    @media screen and (max-width: 768px) {
      :root p {
        font-size: 16px; } }

/* 2 */
[data-theme="dark"] {
  --jaffa-font-color: #eee;
  --jaffa-font-color-secondary: #C7C9D9;
  --jaffa-background-color: #1c1c28;
  --jaffa-background-color-2: #28293D; }

.selection {
  position: relative; }

.container {
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (max-width: 576px) {
    .container {
      width: 95%;
      padding-right: 16px;
      padding-left: 16px; } }
  @media screen and (min-width: 576px) {
    .container {
      width: calc(94%);
      max-width: 1380px;
      padding-right: 24px;
      padding-left: 24px; } }

.externalWebsiteLink {
  text-decoration: none;
  color: #bb86fc;
  font-style: italic; }
  .externalWebsiteLink:visited {
    color: #CF6679; }
  .externalWebsiteLink:hover {
    text-decoration: underline; }
  .externalWebsiteLink:active {
    color: red; }
  .externalWebsiteLink:focus {
    outline: 2px dashed gray; }

:root {
  position: absolute;
  left: 0;
  right: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 1.2;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  --jaffa-font-color: rgb(28, 28, 40);
  --jaffa-font-color-secondary: #555770;
  --jaffa-background-color: #f4f4f5;
  --jaffa-background-color-2: #eeeeea; }
  @media screen and (max-width: 768px) {
    :root {
      font-size: 16px; } }
  :root h1 {
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 34px;
    font-weight: 700; }
    @media screen and (max-width: 768px) {
      :root h1 {
        font-size: 24px;
        font-weight: 500; } }
  :root p {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400; }
    @media screen and (max-width: 768px) {
      :root p {
        font-size: 16px; } }

/* 2 */
[data-theme="dark"] {
  --jaffa-font-color: #eee;
  --jaffa-font-color-secondary: #C7C9D9;
  --jaffa-background-color: #1c1c28;
  --jaffa-background-color-2: #28293D; }

.login {
  display: flex;
  margin-top: 50px;
  padding-top: 50px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap-reverse;
  height: 100%; }
  .login__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    flex: 1; }
    .login__form__social {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      justify-content: center;
      margin-top: 20px; }
    .login__form .divider:after,
    .login__form .divider:before {
      content: " ";
      width: 100px;
      flex: 1;
      height: 1px;
      background: #808080; }
    .login__form .button {
      background: transparent linear-gradient(90deg, #AE0A62 0%, #F8AA43 100%) 0% 0% no-repeat padding-box; }
  .login__welcome {
    flex: 1;
    min-width: 300px; }

.passwordForgot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.container {
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (max-width: 576px) {
    .container {
      width: 95%;
      padding-right: 16px;
      padding-left: 16px; } }
  @media screen and (min-width: 576px) {
    .container {
      width: calc(94%);
      max-width: 1380px;
      padding-right: 24px;
      padding-left: 24px; } }

.externalWebsiteLink {
  text-decoration: none;
  color: #bb86fc;
  font-style: italic; }
  .externalWebsiteLink:visited {
    color: #CF6679; }
  .externalWebsiteLink:hover {
    text-decoration: underline; }
  .externalWebsiteLink:active {
    color: red; }
  .externalWebsiteLink:focus {
    outline: 2px dashed gray; }

:root {
  position: absolute;
  left: 0;
  right: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 1.2;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  --jaffa-font-color: rgb(28, 28, 40);
  --jaffa-font-color-secondary: #555770;
  --jaffa-background-color: #f4f4f5;
  --jaffa-background-color-2: #eeeeea; }
  @media screen and (max-width: 768px) {
    :root {
      font-size: 16px; } }
  :root h1 {
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 34px;
    font-weight: 700; }
    @media screen and (max-width: 768px) {
      :root h1 {
        font-size: 24px;
        font-weight: 500; } }
  :root p {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400; }
    @media screen and (max-width: 768px) {
      :root p {
        font-size: 16px; } }

/* 2 */
[data-theme="dark"] {
  --jaffa-font-color: #eee;
  --jaffa-font-color-secondary: #C7C9D9;
  --jaffa-background-color: #1c1c28;
  --jaffa-background-color-2: #28293D; }

.livestream {
  display: flex;
  flex-direction: row;
  margin-top: 64px;
  padding-top: 10px; }
  @media screen and (max-width: 788px) {
    .livestream {
      margin-top: 92px; } }
  @media screen and (max-width: 920px) {
    .livestream {
      flex-wrap: wrap-reverse; } }
  .livestream #twitter-toggle[aria-expanded="false"] + #twitter-livestream {
    display: none;
    opacity: 0;
    visibility: hidden; }
  .livestream #twitter-toggle[aria-expanded="true"] + #twitter-livestream {
    opacity: 1;
    visibility: visible; }
  .livestream #twitter-toggle[aria-expanded="false"] .fa-times {
    display: none; }
  .livestream #twitter-toggle[aria-expanded="true"] .fa-twitter {
    display: none; }
  .livestream__twitter {
    display: flex; }
    @media screen and (max-width: 920px) {
      .livestream__twitter {
        width: 100%; } }
    .livestream__twitter__content {
      gap: 5px;
      min-width: 300px;
      height: 100vh;
      overflow: scroll;
      z-index: 1;
      margin-top: 40px;
      border-radius: 12px;
      background-color: lightgray;
      /* Hide scrollbar for Chrome, Safari and Opera */
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */ }
      .livestream__twitter__content::-webkit-scrollbar {
        display: none; }
      @media screen and (max-width: 920px) {
        .livestream__twitter__content {
          width: 100%;
          margin: 40px 10px 20px 10px;
          -ms-overflow-style: auto;
          /* IE and Edge */
          scrollbar-width: 5px;
          /* Firefox */ }
          .livestream__twitter__content::-webkit-scrollbar {
            visibility: visible; } }
      .livestream__twitter__content__timeline {
        width: 100%;
        /* Hide scrollbar for Chrome, Safari and Opera */
        transition: all 0.5s;
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */ }
        .livestream__twitter__content__timeline::-webkit-scrollbar {
          display: none; }
    .livestream__twitter__toggle {
      position: absolute;
      right: 20px;
      top: 64px;
      display: flex;
      align-items: center;
      padding: 10px;
      width: 150px;
      margin-top: 64px; }
      @media screen and (max-width: 788px) {
        .livestream__twitter__toggle {
          margin-top: 92px; } }
      .livestream__twitter__toggle i {
        font-size: 24px; }
      @media screen and (max-width: 780px) {
        .livestream__twitter__toggle {
          width: 50px; } }
      .livestream__twitter__toggle__icon {
        pointer-events: none;
        display: flex;
        align-items: center;
        gap: 10px; }
      @media screen and (max-width: 780px) {
        .livestream__twitter__toggle__text {
          display: none; } }
  .livestream__posts {
    display: flex;
    flex-direction: column;
    gap: 20px; }
    .livestream__posts__watchlist {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px; }
    .livestream__posts__commentary {
      display: flex;
      flex-direction: column;
      gap: 20px; }

.container {
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (max-width: 576px) {
    .container {
      width: 95%;
      padding-right: 16px;
      padding-left: 16px; } }
  @media screen and (min-width: 576px) {
    .container {
      width: calc(94%);
      max-width: 1380px;
      padding-right: 24px;
      padding-left: 24px; } }

.externalWebsiteLink {
  text-decoration: none;
  color: #bb86fc;
  font-style: italic; }
  .externalWebsiteLink:visited {
    color: #CF6679; }
  .externalWebsiteLink:hover {
    text-decoration: underline; }
  .externalWebsiteLink:active {
    color: red; }
  .externalWebsiteLink:focus {
    outline: 2px dashed gray; }

:root {
  position: absolute;
  left: 0;
  right: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 1.2;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  --jaffa-font-color: rgb(28, 28, 40);
  --jaffa-font-color-secondary: #555770;
  --jaffa-background-color: #f4f4f5;
  --jaffa-background-color-2: #eeeeea; }
  @media screen and (max-width: 768px) {
    :root {
      font-size: 16px; } }
  :root h1 {
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 34px;
    font-weight: 700; }
    @media screen and (max-width: 768px) {
      :root h1 {
        font-size: 24px;
        font-weight: 500; } }
  :root p {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400; }
    @media screen and (max-width: 768px) {
      :root p {
        font-size: 16px; } }

/* 2 */
[data-theme="dark"] {
  --jaffa-font-color: #eee;
  --jaffa-font-color-secondary: #C7C9D9;
  --jaffa-background-color: #1c1c28;
  --jaffa-background-color-2: #28293D; }

.postArchives {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  padding-top: 10px; }
  @media screen and (max-width: 788px) {
    .postArchives {
      margin-top: 92px; } }
  .postArchives__header {
    text-align: center;
    margin-bottom: 30px; }
  .postArchives__posts {
    display: flex;
    flex-direction: column;
    gap: 20px; }

.tradingPage {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 50px;
  margin-bottom: 100px; }
  .tradingPage__title {
    font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 32px;
    font-weight: 700;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .tradingPage__title {
        font-size: 28px; } }
  .tradingPage__image img {
    width: 100%; }
  .tradingPage__content {
    font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
    font-size: 20px;
    line-height: 1.6;
    margin: 15px 5px; }
    @media screen and (max-width: 768px) {
      .tradingPage__content {
        font-size: 16px; } }
  .tradingPage__highlight {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 16px;
    align-self: center;
    background-color: var(--jaffa-background-color-2);
    gap: 12px;
    font-size: 20px;
    width: fit-content;
    padding: 24px 16px;
    margin: 20px 0px; }
    @media screen and (max-width: 768px) {
      .tradingPage__highlight {
        padding: 16px 8px;
        margin: 10px 0;
        gap: 12px;
        font-size: 18px; } }
  .tradingPage__navigation {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 150px; }
    .tradingPage__navigation__prevPage {
      display: flex;
      padding: 15px 0px;
      align-items: center;
      gap: 10px; }
    .tradingPage__navigation__nextPage {
      display: flex;
      justify-content: flex-end;
      padding: 15px 0px;
      align-items: center;
      gap: 10px; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  scroll-behavior: smooth; }

/* 3 */
body {
  position: relative;
  color: var(--jaffa-font-color);
  background-color: var(--jaffa-background-color);
  line-height: 1.2; }

#tradingView {
  position: absolute;
  top: 64px;
  height: 74px;
  width: 100%; }
  @media screen and (min-width: 768px) {
    #tradingView {
      height: 46px; } }

/* Custom Dark Mode Toggle Element */
.toggle-theme-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px; }

.toggle-theme-wrapper span {
  font-size: 28px; }

.toggle-theme {
  position: relative;
  display: inline-block;
  height: 34px;
  width: 60px; }

.toggle-theme input {
  display: none; }

.slider {
  background-color: #ccc;
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.2s; }

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px; }

input:checked + .slider:before {
  transform: translateX(26px); }

input:checked + .slider {
  background-color: cornflowerblue; }

.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }
